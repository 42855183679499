import DetectRTC from 'detectrtc'
import { safeObject } from 'uikit'

export const getMetadata = cameraLabel => new Promise(((resolve) => {
  DetectRTC.load(() => {
    const osName = DetectRTC.osName
    const osVersion = DetectRTC.osVersion
    const isMobileDevice = DetectRTC.isMobileDevice
    const browserName = DetectRTC.browser.name
    const browserVersion = DetectRTC.browser.version
    const videoInputDevices = DetectRTC.videoInputDevices.map(device => device.label)
    resolve(safeObject({
      OsName: osName || null,
      OsVersion: osVersion || null,
      IsMobileDevice: isMobileDevice || null,
      BrowserName: browserName || null,
      BrowserVersion: browserVersion.toString() || null,
      VideoInputDevices: videoInputDevices || null,
      SelectedDevice: cameraLabel || null
    }))
  })
}))
